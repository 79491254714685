import React, { useState } from "react";
import './scrollDown.css'; 

const ScrollDown = () => {
    const [activeLink, setActiveLink] = useState('home');
    const [scrolled, setScrolled] = useState(false);

    const handleLinkClick = (id) => {
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: "smooth" });
        setActiveLink(id);
    };

    return (
        <div className="scroll__down">
                <div className="mouse__wrapper" onClick={() => handleLinkClick('about')}>
                <span className="home__scroll-name">Scroll Down</span>
                <span className="mouse">
                    <span className="wheel"></span>
                </span>
            </div>
        </div>
    )
}

export default ScrollDown