// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume__container {
    grid-template-columns: repeat(2,1fr);
    column-gap: 1.875rem;
}

.timeline {
    background-color: var(--container-color);
    padding: 1.875rem;
    border-radius: var(--border-radius);
    position: relative;
    box-shadow: var(--shadow);
}

.timeline__item {
    position: relative;
    padding-left: 3.125rem;
    padding-bottom: 3.125rem;
}

.timeline__item:last-child {
    padding-bottom: 0;
}

.timeline__item::before {
    content:'';
    width: 1px;
    height: 100%;
    background-color: var(--first-color);
    position: absolute;
    left: .25rem;
    top: 0;
}

.timeline .icon-briefcase, .timeline .icon-graduation{
    position:absolute;
    left: -0.4375rem;
    top: 0;
    font-size: var(--h2-font-size);
    color: var(--first-color);
    background-color: var(--container-color);
    padding: 0.4375rem 0;
}

.timeline__date {
    color: #6c757d;
    font-size: var(--small-font-size);
}

.timeline__title {
    font-size: var(--h3-font-size);
    margin: 0.5rem 0;
}

`, "",{"version":3,"sources":["webpack://./src/components/resume/resume.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,oBAAoB;AACxB;;AAEA;IACI,wCAAwC;IACxC,iBAAiB;IACjB,mCAAmC;IACnC,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,wBAAwB;AAC5B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,UAAU;IACV,YAAY;IACZ,oCAAoC;IACpC,kBAAkB;IAClB,YAAY;IACZ,MAAM;AACV;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,MAAM;IACN,8BAA8B;IAC9B,yBAAyB;IACzB,wCAAwC;IACxC,oBAAoB;AACxB;;AAEA;IACI,cAAc;IACd,iCAAiC;AACrC;;AAEA;IACI,8BAA8B;IAC9B,gBAAgB;AACpB","sourcesContent":[".resume__container {\r\n    grid-template-columns: repeat(2,1fr);\r\n    column-gap: 1.875rem;\r\n}\r\n\r\n.timeline {\r\n    background-color: var(--container-color);\r\n    padding: 1.875rem;\r\n    border-radius: var(--border-radius);\r\n    position: relative;\r\n    box-shadow: var(--shadow);\r\n}\r\n\r\n.timeline__item {\r\n    position: relative;\r\n    padding-left: 3.125rem;\r\n    padding-bottom: 3.125rem;\r\n}\r\n\r\n.timeline__item:last-child {\r\n    padding-bottom: 0;\r\n}\r\n\r\n.timeline__item::before {\r\n    content:'';\r\n    width: 1px;\r\n    height: 100%;\r\n    background-color: var(--first-color);\r\n    position: absolute;\r\n    left: .25rem;\r\n    top: 0;\r\n}\r\n\r\n.timeline .icon-briefcase, .timeline .icon-graduation{\r\n    position:absolute;\r\n    left: -0.4375rem;\r\n    top: 0;\r\n    font-size: var(--h2-font-size);\r\n    color: var(--first-color);\r\n    background-color: var(--container-color);\r\n    padding: 0.4375rem 0;\r\n}\r\n\r\n.timeline__date {\r\n    color: #6c757d;\r\n    font-size: var(--small-font-size);\r\n}\r\n\r\n.timeline__title {\r\n    font-size: var(--h3-font-size);\r\n    margin: 0.5rem 0;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
