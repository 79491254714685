// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* footer.css */
.footer {
    width: 100%;
    height: 100px; /* Adjust height as needed */
    bottom: 0; /* Align it to the bottom */
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/footer/footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;IACI,WAAW;IACX,aAAa,EAAE,4BAA4B;IAC3C,SAAS,EAAE,2BAA2B;IACtC,OAAO;IACP,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":["/* footer.css */\n.footer {\n    width: 100%;\n    height: 100px; /* Adjust height as needed */\n    bottom: 0; /* Align it to the bottom */\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
