// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.blog__container {
    grid-template-columns: repeat(3, 1fr);
    column-gap: 1.875rem;
}

.blog__card {
    box-shadow: var(--shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
}

.blog__thumb {
    position: relative;
    overflow: hidden;
}

.blog__img {
    transform: scale(1);
    transition: .3s;
}

.blog__thumb:hover .blog__img {
    transform: scale(1.1);
}

.blog__category{
    color: #fff;
    background-color: var(--first-color);
    border-bottom-left-radius: 0.9375rem;
    border-bottom-right-radius: 0.9375rem;
    position: absolute;
    top: 0;
    left: 1.25rem;
    font-size: var(--small-font-size);
    display: inline-block;
    padding: 0.125rem 0.5rem;
    transition: .3s;
    z-index: 1;
}

.blog__details {
    background-color: var(--container-color);
    padding: 1.25rem;
}

.blog__title {
    font-size: var(--h3-font-size);
    margin-bottom: 0.5rem;
}

.blog__meta {
    display: flex;
    column-gap: 0.5rem;
    font-size: var(--small-font-size);
    color: #8b88b1;
}

.blog__dot{
    font-weight: var(--font-bold);
}`, "",{"version":3,"sources":["webpack://./src/components/blog/blog.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,oBAAoB;AACxB;;AAEA;IACI,yBAAyB;IACzB,mCAAmC;IACnC,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,oCAAoC;IACpC,oCAAoC;IACpC,qCAAqC;IACrC,kBAAkB;IAClB,MAAM;IACN,aAAa;IACb,iCAAiC;IACjC,qBAAqB;IACrB,wBAAwB;IACxB,eAAe;IACf,UAAU;AACd;;AAEA;IACI,wCAAwC;IACxC,gBAAgB;AACpB;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;IACI,aAAa;IACb,kBAAkB;IAClB,iCAAiC;IACjC,cAAc;AAClB;;AAEA;IACI,6BAA6B;AACjC","sourcesContent":[".blog__container {\r\n    grid-template-columns: repeat(3, 1fr);\r\n    column-gap: 1.875rem;\r\n}\r\n\r\n.blog__card {\r\n    box-shadow: var(--shadow);\r\n    border-radius: var(--border-radius);\r\n    overflow: hidden;\r\n}\r\n\r\n.blog__thumb {\r\n    position: relative;\r\n    overflow: hidden;\r\n}\r\n\r\n.blog__img {\r\n    transform: scale(1);\r\n    transition: .3s;\r\n}\r\n\r\n.blog__thumb:hover .blog__img {\r\n    transform: scale(1.1);\r\n}\r\n\r\n.blog__category{\r\n    color: #fff;\r\n    background-color: var(--first-color);\r\n    border-bottom-left-radius: 0.9375rem;\r\n    border-bottom-right-radius: 0.9375rem;\r\n    position: absolute;\r\n    top: 0;\r\n    left: 1.25rem;\r\n    font-size: var(--small-font-size);\r\n    display: inline-block;\r\n    padding: 0.125rem 0.5rem;\r\n    transition: .3s;\r\n    z-index: 1;\r\n}\r\n\r\n.blog__details {\r\n    background-color: var(--container-color);\r\n    padding: 1.25rem;\r\n}\r\n\r\n.blog__title {\r\n    font-size: var(--h3-font-size);\r\n    margin-bottom: 0.5rem;\r\n}\r\n\r\n.blog__meta {\r\n    display: flex;\r\n    column-gap: 0.5rem;\r\n    font-size: var(--small-font-size);\r\n    color: #8b88b1;\r\n}\r\n\r\n.blog__dot{\r\n    font-weight: var(--font-bold);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
