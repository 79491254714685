// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scroll__down {
    position: absolute;
    bottom: 2.5rem;
    left: 0;
    width: 100%;
}

.home__scroll-name {
    font-size: var(--small-font-size);
    margin-bottom: 0.5rem;
    color:var(--text-color)
}

.mouse {
    border: 2px solid var(--text-color);
    display: block;
    height: 1.6rem;
    width: 1.25rem;
    margin: auto;
    margin-top: .75rem;
    margin-bottom: 0rem;
    border-radius: 1rem;
    position: relative;
}

@keyframes ani-mouse {
    0% {
        top: 29%;
    }

    15% {
        top: 50%;
    }

    50% {
        top: 50%;
    }

    100% {
        top: 29%;
    }
}

.wheel {
    background-color: var(--text-color);
    border-radius: 100%;
    width: 0.25rem;
    height: 0.25rem;
    position: absolute;
    top: .5rem;
    left: 50%;
    transform: translateX(-50%);
    animation: ani-mouse 2s linear infinite;
}

.shapes {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/home/scrollDown.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,cAAc;IACd,OAAO;IACP,WAAW;AACf;;AAEA;IACI,iCAAiC;IACjC,qBAAqB;IACrB;AACJ;;AAEA;IACI,mCAAmC;IACnC,cAAc;IACd,cAAc;IACd,cAAc;IACd,YAAY;IACZ,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI;QACI,QAAQ;IACZ;;IAEA;QACI,QAAQ;IACZ;;IAEA;QACI,QAAQ;IACZ;;IAEA;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI,mCAAmC;IACnC,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,2BAA2B;IAC3B,uCAAuC;AAC3C;;AAEA;IACI,kBAAkB;IAClB,OAAO;IACP,MAAM;IACN,YAAY;IACZ,WAAW;AACf","sourcesContent":[".scroll__down {\n    position: absolute;\n    bottom: 2.5rem;\n    left: 0;\n    width: 100%;\n}\n\n.home__scroll-name {\n    font-size: var(--small-font-size);\n    margin-bottom: 0.5rem;\n    color:var(--text-color)\n}\n\n.mouse {\n    border: 2px solid var(--text-color);\n    display: block;\n    height: 1.6rem;\n    width: 1.25rem;\n    margin: auto;\n    margin-top: .75rem;\n    margin-bottom: 0rem;\n    border-radius: 1rem;\n    position: relative;\n}\n\n@keyframes ani-mouse {\n    0% {\n        top: 29%;\n    }\n\n    15% {\n        top: 50%;\n    }\n\n    50% {\n        top: 50%;\n    }\n\n    100% {\n        top: 29%;\n    }\n}\n\n.wheel {\n    background-color: var(--text-color);\n    border-radius: 100%;\n    width: 0.25rem;\n    height: 0.25rem;\n    position: absolute;\n    top: .5rem;\n    left: 50%;\n    transform: translateX(-50%);\n    animation: ani-mouse 2s linear infinite;\n}\n\n.shapes {\n    position: absolute;\n    left: 0;\n    top: 0;\n    height: 100%;\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
