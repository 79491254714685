// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);

    display: flex;
    justify-content: center;
    align-items: center;
}

.popup__inner {
    position: relative;
    padding: 32px;
    width: 100%;
    max-width: 640px; 
    background-color: var(--container-color);
    border-radius: var(--border-radius);
}

.popup__inner .close__btn {
    position: absolute;
    top: 16px;
    right: 24px;
    background-color: var(--container-color);
}`, "",{"version":3,"sources":["webpack://./src/components/popup/popup.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,iCAAiC;;IAEjC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,WAAW;IACX,gBAAgB;IAChB,wCAAwC;IACxC,mCAAmC;AACvC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,wCAAwC;AAC5C","sourcesContent":[".popup {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100%;\r\n    height: 100vh;\r\n    background-color: rgba(0,0,0,0.7);\r\n\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n}\r\n\r\n.popup__inner {\r\n    position: relative;\r\n    padding: 32px;\r\n    width: 100%;\r\n    max-width: 640px; \r\n    background-color: var(--container-color);\r\n    border-radius: var(--border-radius);\r\n}\r\n\r\n.popup__inner .close__btn {\r\n    position: absolute;\r\n    top: 16px;\r\n    right: 24px;\r\n    background-color: var(--container-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
