// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.svg__body {
    background-color: #fdffb6;
}

.svg__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: -1; /* Ensure the SVG stays in the background */
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.out-top {
    animation: rotate 180s linear infinite;
    transform-origin: 13px 25px;
}

.in-top {
    animation: rotate 140s linear infinite;
    transform-origin: 13px 25px;
}

.out-bottom {
    animation: rotate 200s linear infinite;
    transform-origin: 84px 93px;
}

.in-bottom {
    animation: rotate 160s linear infinite;
    transform-origin: 84px 93px;
}`, "",{"version":3,"sources":["webpack://./src/assets/background/animatedBackground.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,aAAa;IACb,WAAW,EAAE,2CAA2C;AAC5D;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ;;AAEA;IACI,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;IACI,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;IACI,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;IACI,sCAAsC;IACtC,2BAA2B;AAC/B","sourcesContent":[".svg__body {\n    background-color: #fdffb6;\n}\n\n.svg__bg {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100vh;\n    z-index: -1; /* Ensure the SVG stays in the background */\n}\n\n@keyframes rotate {\n    0% {\n        transform: rotate(0deg);\n    }\n    100% {\n        transform: rotate(360deg);\n    }\n}\n\n.out-top {\n    animation: rotate 180s linear infinite;\n    transform-origin: 13px 25px;\n}\n\n.in-top {\n    animation: rotate 140s linear infinite;\n    transform-origin: 13px 25px;\n}\n\n.out-bottom {\n    animation: rotate 200s linear infinite;\n    transform-origin: 84px 93px;\n}\n\n.in-bottom {\n    animation: rotate 160s linear infinite;\n    transform-origin: 84px 93px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
